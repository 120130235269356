import React from "react";
import loading from "../../assets/img/icons/success.svg";

const SuccesMessage = ({ handleClose }) => {
  return (
    <div className='modal-message-successful'>
      <div className='wrapper'>
        <img src={loading} alt='Loading' />
        <div className='main-subtitle center primary-color large'>
          Your message was successfully sent.
        </div>
        <p className='remove-br-mobile'>
          Should you require further assistance, <br />
          you may reach us at <a href='tel:+971 2 6436640'>+971 2 6436640</a> or
          send an email to <a href='mailto:info@virsat.com'>info@virsat.com</a>.
        </p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccesMessage;
