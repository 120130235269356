import React, { useState } from "react";

const TextArea = ({
  name,
  id,
  placeholder,
  divClass,
  divStyle,
  txtAreaClass,
  txtAreaStyle,
  onChange = () => {},
  the_ref,
  value = "",
  error = false,
  required = false,
  cols = 10,
  rows = 5,
}) => {
  const [activeBorder, setActiveBorder] = useState(false);
  //const txtValue = useRef("")

  const handleFocus = (e) => {
    setActiveBorder(true);
  };

  const handleBlur = (e) => {
    setActiveBorder(false);
  };

  return (
    <div
      className={`field-container light-border ${
        error ? "error" : ""
      } ${divClass} ${activeBorder && "active"}`}
      style={{ divStyle }}
    >
      <label className={`textarea ${value && "always-active"}`} htmlFor={id}>
        {placeholder}
      </label>
      <textarea
        className={txtAreaClass}
        style={{ txtAreaStyle }}
        name={name}
        id={id}
        //placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        ref={the_ref}
        required={required}
        cols={cols}
        rows={rows}
        value={value}
      ></textarea>
    </div>
  );
};

export default TextArea;
