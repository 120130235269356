import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import axios from "axios";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import Loading from "../components/common/Loading";
import SuccesMessage from "../components/common/SuccesMessage";
import ErrorMessage from "../components/common/ErrorMessage";

import phoneImg from "../assets/img/icons/phone.svg";
import emailImg from "../assets/img/icons/email.svg";
import locationImg from "../assets/img/icons/location.svg";

import TextBox from "../components/inputs/Textbox";
import PhoneNumber from "../components/inputs/PhoneNumber";
import TextArea from "../components/inputs/TextArea";

import { GlobalStateContext } from "../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

const Contact = () => {
  const [txtValues, setTxtValues] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [success, setSuccess] = useState(-1);
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const state = useContext(GlobalStateContext);

  let container = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ ...txtValues });

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/contact-form`,
        body,
        config
      );

      if (res.data.status) {
        setSuccess(1);
        setErrors({});
        setTxtValues({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
      } else {
        setSuccess(0);
        setErrors(res.data.errors);
      }
      setSending(false);
    } catch (err) {
      setSuccess(0);
      setSending(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(-1);
    sendData();
  };

  const handleClose = () => {
    setSuccess(-1);
    setErrors({});
  };

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      {sending && <Loading />}
      {success === 1 && <SuccesMessage handleClose={handleClose} />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Layout contactPage>
        <Seo
          title='Contact Us | VIRSAT - Virtual Reality Safety Training'
          description='We are happy to provide information tailored to your company’s need. Please call us or send an email.'
        />

        <PageBanner
          title='Contact Us'
          subtitle='We are more than happy to help'
          customClass='contact'
        />

        <section
          className='main-container home-contact-us contact-page hide-container'
          ref={container}
        >
          <div className='row top-pad bottom-pad'>
            <div className='column'>
              <div className='details'>
                <h2 className='main-title '>Contact Us</h2>
                <p className=' remove-br-mobile'>
                  We are happy to provide information tailored to your company’s
                  need. <br />
                  Please call us or send an email.
                </p>
                <ul className='contact-us-ul'>
                  <li className='contact-us-li'>
                    <div className='icon-container'>
                      <img src={phoneImg} alt='Phone' />
                    </div>
                    <div className='contact-details'>
                      <span className='contact-name'>Phone</span>
                      <br />
                      <a href='tel:+97126436640' className='contact-value'>
                        +971 2 643 6640
                      </a>
                    </div>
                  </li>
                  <li className='contact-us-li'>
                    <div className='icon-container'>
                      <img src={emailImg} alt='Email' />
                    </div>
                    <div className='contact-details'>
                      <span className='contact-name'>Email</span>
                      <br />
                      <a
                        href='mailto:info@virsat.com'
                        className='contact-value'
                      >
                        info@virsat.com
                      </a>
                    </div>
                  </li>
                  <li className='contact-us-li'>
                    <div className='icon-container'>
                      <img src={locationImg} alt='Location' />
                    </div>
                    <div className='contact-details'>
                      <span className='contact-name'>Address</span>
                      <br />
                      <p className=''>
                        <span className='country'>USA: </span>
                        1400 Preston Road, #400 / Plano, Texas 75093
                      </p>
                      <p className='no-mar'>
                        <span className='country'>UAE: </span>
                        Tamouh Tower, Bldg. 12, 22nd Floor, Marina Square, Al Reem Island, Abu Dhabi
                      </p>
                      <p className='no-mar'>
                        <span className='country'>Netherlands: </span>
                        Plantagekade 10, Postcode 1018 ZV, Amsterdam
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='form'>
                <form action='' method='post' onSubmit={handleSubmit}>
                  <div className='label-container'>
                    <label className='' htmlFor='name'>
                      Name*
                    </label>
                    <TextBox
                      type='text'
                      id='name'
                      name='name'
                      placeholder='Eg. John Doe'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.name}
                      required
                      error={errors.name}
                    />
                    {errors.name && (
                      <div className='field-error'>{errors.name[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='email'>
                      Email*
                    </label>
                    <TextBox
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Eq. johndoe@yourcompany.com'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.email}
                      error={errors.email}
                      required
                    />
                    {errors.email && (
                      <div className='field-error'>{errors.email[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='mobile'>
                      Mobile
                    </label>
                    <PhoneNumber
                      name='mobile'
                      id='mobile'
                      value={txtValues.mobile}
                      onChange={(mobile) =>
                        setTxtValues({ ...txtValues, mobile: mobile })
                      }
                      txtBoxClass='regular-text'
                    />
                    {errors.mobile && (
                      <div className='field-error'>{errors.mobile[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='message'>
                      Message*
                    </label>
                    <TextArea
                      name='message'
                      id='message'
                      placeholder='Enter your message here...'
                      onChange={handleInputChange}
                      value={txtValues.message}
                      error={errors.message ? true : false}
                      txtAreaClass='regular-text'
                      required
                    />
                    {errors.message && (
                      <div className='field-error'>{errors.message[0]}</div>
                    )}
                  </div>
                  <button type='submit'>Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Clients />
      </Layout>
    </Fragment>
  );
};

export default Contact;
